import React from 'react';

import './style.scss';

const DEFAULT_IMAGE_LINK = 'https://storage.googleapis.com/flype_public_images/bag_image.jpg'

const Parcel = ({ data }) => {
    return (
        <div className="Parcel spaced-24">
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img className="parcelImage" src={data.picture ? data.picture : DEFAULT_IMAGE_LINK}></img>
            <div className="Parcel__info spaced-left-12">
                <div className="Parcel__headline text-title-2">
                    {data.quantity + ' x ' + data.description}
                </div>
                <div className="Parcel__subheadline text-subheadline">
                    {data.info ? data.info : ''}
                </div>

                {/*
                <div className="Parcel__price text-subheadline">
                    465 SEK [NEED SOURCE]
                </div>
                */}
            </div>
        </div>
    );
};

const getParcelList = (items) => {
    if (!items) {
        return null;
    }

    return items.map((item, idx) => {
        return <Parcel key={idx} data={item} />;
    });
};

const ParcelInfo = ({ data }) => {
    return (
        <div>
            <h2 className="text-title-1">Delivery</h2>
            {getParcelList(data)}
        </div>
    );
};

export { ParcelInfo };
