import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import { NotFoundScreen } from './App';
import Settings from './components/Settings';
import { messaging } from './libs/firebase/messaging-init';
import * as serviceWorker from './serviceWorker';

// React Router
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

if ('serviceWorker' in navigator && messaging) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js');
}

const root = (
    <React.StrictMode>
        <Router>
            <Switch>
                <Route
                    exact
                    path="/settings/:deliveryId"
                    component={Settings}
                />
                <Route exact path="/:deliveryId" component={App} />
                <Route exact path="/" component={NotFoundScreen} />
            </Switch>
        </Router>
    </React.StrictMode>
);

const container = document.getElementById('root');
const rootTree = createRoot(container);
rootTree.render(root);

serviceWorker.unregister();
