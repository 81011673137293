import {getLocale} from './userLocale';

/**
 * Returns a string representing the date based on the provided zuluDateTime.
 *
 * If the zuluDateTime is the same as the current date, the function returns 'Today'.
 * If the zuluDateTime is the next day, the function returns 'Tomorrow'.
 * Otherwise, the function returns a string representing the date in the format 'Wed Dec 08 2021'.
 *
 * @param {string} zuluDateTime - The zulu datetime string to be converted to a date string.
 * @returns {string} - A string representing the date based on the provided zuluDateTime.
 */
const dateFromDateString = (zuluDateTime) => {
    const when = new Date(zuluDateTime).setHours(0, 0, 0, 0);
    const today = new Date().setHours(0, 0, 0, 0);
    const tomorrow = new Date().setHours(24, 0, 0, 0);

    if (today === when) {
        return 'Today';
    } else if (tomorrow === when) {
        return 'Tomorrow';
    }

    return new Date(when).toDateString();
};


/**
 * Converts a given date object to a time string formatted based on the device's locale.
 *
 * @param {Date} date - The date object to be formatted.
 * @returns {string|undefined} - A string representing the time in the format of 'hour:numeric minute:numeric' based on the device's locale, or undefined if no date is provided.
 */
const getTimeBasedOnDeviceLocale = date => {
    if (!date) {
        return undefined;
    }
    const locale = getLocale();
    const dateOptions = {
        hour: 'numeric',
        minute: 'numeric',
    };

    return date.toLocaleTimeString(locale, dateOptions);
};

/**
 * Converts a given date to a string formatted based on the device's locale settings.
 *
 * @param {Date | string} date - The date to be formatted. Can be a Date object or an ISO date string.
 * @returns {string | undefined} The date formatted according to the device's locale, or undefined if no date is provided.
 */
const getDateBasedOnDeviceLocale = date => {
    if (!date) {
        return undefined;
    }

    let localDate;
    if (typeof date === 'string') {
        localDate = new Date(date);
    } else {
        localDate = date;
    }

    let options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric' };
    return localDate.toLocaleDateString(getLocale(), options);
};

const hourMinutesFromDateString = (zuluDateTime) => {
    const theMoment = new Date(zuluDateTime);
    return getTimeBasedOnDeviceLocale(theMoment);
    /*
    const time = `${theMoment.getHours()}:${(
        '' + theMoment.getMinutes()
    ).padStart(2, '0')}`;
    return time;
    */
};

/**
 * Calculate start of delivery interval
 * @param zuluDateTime {Date} is the date object
 * @param start_moment {Date} is whether the delivery route has started or not
 * @param start_hour {Number} is the start hour of the shift
 * @param endTime {boolean} is whether the function is processing start or end delivery time
 * @return {string}
 */
const minimumHourMinutesFromDateString = (zuluDateTime, start_moment, start_hour= 15, endTime = false) => {
    const theMoment = prepareMinimumTimeOfDeparture(zuluDateTime, start_moment, start_hour, endTime);
    return getTimeBasedOnDeviceLocale(theMoment);
    /*
    const time = `${theMoment.getHours()}:${(
        '' + theMoment.getMinutes()
    ).padStart(2, '0')}`;
    return time;
    */
};

/**
 * Prepare delivery time intervall to show
 * @param zuluDateTime {Date} is the date object
 * @param start_moment {Date} is whether the delivery route has started or not
 * @param start_hour {Number} is the start hour of the shift
 * @param endTime {boolean} is whether the function is processing start or end delivery time
 * @return {Date}
 */
const prepareMinimumTimeOfDeparture = (zuluDateTime, start_moment = undefined, start_hour = 15, endTime = false) => {
    const theMoment = new Date(zuluDateTime);
    if (!start_moment && !endTime) {
        if (theMoment.getDay() > 0 && theMoment.getDay() < 6) {
            theMoment.setHours(start_hour, 0);
            /*
            if (theMoment.getHours() < 14 && theMoment.getHours() > 2) {
                theMoment.setHours(15, 0);
            }
            */
        } else {
            theMoment.setHours(start_hour, 0);
            /*
            if (theMoment.getHours() < 14 && theMoment.getHours() > 2) {
                theMoment.setHours(14, 0);
            }
            */
        }
    }
    return theMoment;
};

const getEstimation = (zuluDateTime) => {
    return prepareMinimumTimeOfDeparture(zuluDateTime);
};

export {
    dateFromDateString,
    hourMinutesFromDateString,
    getEstimation,
    minimumHourMinutesFromDateString,
    prepareMinimumTimeOfDeparture,
    getDateBasedOnDeviceLocale,
};
