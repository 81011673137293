import React from 'react';
// import { Button } from 'react-native-paper';
import {dateFromDateString, hourMinutesFromDateString,} from '../../libs/utils';
// import { Button, FabButton } from '../Button';
import './style.scss';

const TEXTS = {
    IN_STORE: (vars) =>
        `A Flyper will soon pick up your order from ${vars.retailer.name}`,

    ASSIGNED: (vars) => {
        return `We have assigned ${vars.traveller.firstName} to deliver your order from ${vars.retailer.name}.`;
    },

    PICKED_UP: (vars) => {
        return `${vars.traveller.firstName} has picked up your order from ${vars.retailer.name} and is on the way!`;
    },

    FAILED: (vars) => {
        return `${vars.traveller.firstName} just tried to deliver but could not reach you. Please contact your Flyper or LiveChat support to book a new delivery`;
    },

    // DELIVERING: (vars) => {
    //     return `Delivery is taking place please confirm by providing the code and accepting the delivery`;
    // },

    DELIVERED: (vars) => {
        return `Your order from ${vars.retailer.name} has arrived! Don't forget to give ${vars.traveller.firstName} a rating :)`;
    },

    REVIEWED: (vars) => {
        return `Your feedback helps us improve our service, thank you!`;
    },

    DEFAULT: (vars) =>
        `We have planned your delivery from ${
            vars.retailer.name
        } at ${dateFromDateString(vars.expected)}, ${hourMinutesFromDateString(
            vars.expected,
        )}.`,
};

const StatusCard = ({ data, id, delivery, traveller }) => {
    if (!TEXTS[data.status]) {
        return null;
    }

    return (
        <div className="Feed-card">
            <div className="Feed-card__header">
                <div>
                    <span className="Icon Icon-flype"></span>
                    <span className="spaced-left-12">Flype</span>
                </div>
                <div>
                    {dateFromDateString(data.moment)},{' '}
                    {hourMinutesFromDateString(data.moment)}
                </div>
            </div>
            <div className="Feed-card__main">
                {TEXTS[data.status]({
                    ...data,
                    ...delivery,
                    ...{ traveller: traveller },
                })}
                {data.picture ? (
                    <a href={data.picture} target={'_picture'}>
                        <img
                            style={{
                                float: 'right',
                                borderRadius: 10,
                                marginTop: 8,
                                width: 53,
                                height: 53,
                            }}
                            alt={'Delivery dropped outside the door'}
                            src={data.picture}
                        />
                    </a>
                ) : null}
            </div>
        </div>
    );
};

const getCardList = (items, delivery, traveller) => {
    if (!items || !delivery || !traveller) {
        return null;
    }

    // handle fail moment case and sort array
    if (delivery.fail_moment && !items.find(object => object.status === "FAILED")) {
        items.push({moment: delivery.fail_moment, status: "FAILED"});
        items = items.sort((a, b) => new Date(a.moment) - new Date(b.moment));
    }

    const cards = items
        .map((item, idx) => {
            return (
                <StatusCard
                    key={idx}
                    id={idx}
                    data={item}
                    delivery={delivery}
                    traveller={traveller}
                />
            );
        })
        .reverse();
    return cards;
    // return [<StatusCard />, <StatusCard />, <StatusCard />];
};

const StatusCards = ({ data, delivery, traveller }) => {
    return (
        <section className="Section bg-gradient-blue Grid Grid--centered">
            <div className="Grid__column">
                <div className="spacer-32"></div>
                <h2 className="text-title-1 ">Delivery events</h2>

                <div className="spacer-16"></div>

                <div className="">{getCardList(data, delivery, traveller)}</div>

                <div className="spacer-16"></div>
            </div>
        </section>
    );
};

export { StatusCards };
