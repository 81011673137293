const firebaseConfig = {
    apiKey: 'AIzaSyAGYoU0G2zLu2aGkWDGgAaoZPLtfaU45sY',
    authDomain: 'flype-a8461.firebaseapp.com',
    databaseURL: 'https://flype-a8461.firebaseio.com',
    projectId: 'flype-a8461',
    storageBucket: 'flype-a8461.appspot.com',
    messagingSenderId: '307499181580',
    appId: '1:307499181580:web:97f6688d48e8507b043029',
    measurementId: 'G-K54LJFDZSX',
};

export default firebaseConfig;
