import axios from 'axios';
import { ApiResponseError } from '../errorTypes';

const createApiError = (error) => {
    const errorToThrow = new ApiResponseError(error);
    // TODO: SEND ERROR TO REMOTE LOG IF NEEDED
    console.log('ERROR', JSON.stringify(errorToThrow.getData()));
    return errorToThrow;
};

const makeRequest = (config, extra = {}) => {
    const defaultExtra = {};
    const prepareExtra = Object.assign(defaultExtra, extra);

    const prepareConfig = Object.assign({}, prepareExtra, config);

    return axios(prepareConfig)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw createApiError(error);
        });
};

const getAction = (url, params = {}, headers = {}, extra = {}) => {
    return makeRequest({ method: 'get', url, params, headers }, extra);
};

const postAction = (url, params = {}, data = {}, headers = {}, extra = {}) => {
    return makeRequest({ method: 'post', url, params, data, headers }, extra);
};

const putAction = (url, params = {}, data = {}, headers = {}, extra = {}) => {
    return makeRequest({ method: 'put', url, params, data, headers }, extra);
};

const deleteAction = (
    url,
    params = {},
    data = {},
    headers = {},
    extra = {},
) => {
    return makeRequest({ method: 'delete', url, params, data, headers }, extra);
};

const exportedObject = {
    get: getAction,
    post: postAction,
    put: putAction,
    delete: deleteAction,
};
export default exportedObject;
