import React from 'react';
// import { Button } from 'react-native-paper';
import './style.scss';
import '../../foundation/Icon.scss';

const Button = ({
    title,
    type,
    disabled = false,
    onClick /*, onPress, disabled, margin = 25*/,
}) => {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={
                'Button ' + (type === 'secondary' ? 'Button--secondary' : '')
            }>
            {title}
        </button>
    );
};

const FabButton = ({ onClick, icon, title = icon }) => {
    return (
        <button
            onClick={onClick}
            className={'Button Button--fab'}
            title={title}>
            <span className={'Icon Icon-' + icon}></span>
        </button>
    );
};

export { Button, FabButton };
