import React from 'react';

import './style.scss';

const ScreenHeader = ({ children }) => {
    return <div className="Hero-card__header">{children}</div>;
};

const ScreenMain = ({
    title,
    children,
    openCaseId,
    openCaseInfo,
    onSeeCaseDetails,
}) => {
    const getStateVisual = ({ state }) => {
        switch (state) {
            case 1: // ACTIVE
                return <span className="Icon Icon-alert"></span>;
            case 2: // CANCELLED
            case 3: // CLOSED
            case 4: // SOLVED
            default:
                return null;
        }
    };
    const getDamageText = ({ type }) => {
        switch (type) {
            case 'damage':
                return 'Damaged item report';
            case 'lost':
                return 'Lost delivery report';
            case 'delayed':
                return 'Delayed delivery report';
            default:
                return 'General delivery report';
        }
    };

    return (
        <div className="Hero-card__main">
            <h1 className="text-title-1">{title}</h1>
            {openCaseId && openCaseInfo ? (
                <h5 className="OpenReport">
                    {getStateVisual(openCaseInfo)}&nbsp;
                    {getDamageText(openCaseInfo)}&nbsp;
                    <span
                        onClick={onSeeCaseDetails}
                        style={{
                            cursor: 'pointer',
                            color: '#2EB4FF',
                            fontWeight: '500',
                        }}>
                        (See more)
                    </span>
                </h5>
            ) : null}
            <div className="Icon Icon-flype Icon-flype--large spaced-32"></div>
            <div className="spaced-32"></div>
            {children}
            {/* <div className="spaced-32"></div> */}
        </div>
    );
};

const ScreenFooter = ({ children }) => {
    return <div className="Hero-card__footer">{children}</div>;
};

const Screen = ({
    header = null,
    title = '[TITLE]',
    footer = null,
    children = '[CONTENT]',
    openCaseId,
    openCaseInfo,
    onSeeCaseDetails,
}) => {
    return (
        <section className="Section Grid@medium-up">
            <div className="Hero-card Grid">
                <ScreenHeader>{header}</ScreenHeader>
                <div
                    className={`Hero-card__main${!header ? '_no_header' : ''}`}>
                    <ScreenMain
                        onSeeCaseDetails={onSeeCaseDetails}
                        openCaseId={openCaseId}
                        openCaseInfo={openCaseInfo}
                        title={title}>
                        {children}
                    </ScreenMain>
                    <ScreenFooter>{footer}</ScreenFooter>
                </div>
            </div>
        </section>
    );
};

export { Screen };
