import React, { useEffect, useState } from 'react';
// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import loadLiveChat from './libs/livechat';
import { messaging } from './libs/firebase/messaging-init';
import {
    getDeliveryInformation,
    getDeliveryStatusHistory,
    getDeliveryParcels,
    getDeliveryTraveller,
    getCaseReport,
    getCaseReportDetails,
    registerNotificationToken,
    getDeliveryChat,
    sendDeliverChatMessage,
    acceptDeliveryWithCode,
    sendTravellerReview,
    sendCaseReport,
    sendCaseReportMessage,
    getSettings,
    getPickupTime,
    // getTravellerRating,
} from './libs/api';

import logo from './assets/logo.svg';
import './App.scss';
import Spinner from './components/Spinner';

import { useHistory, useLocation } from 'react-router-dom';

import { NotFound } from './screens/notFound';
import { Main } from './screens/main';
import { Chat } from './screens/chat';
import { Case } from './screens/case';

export const NotFoundScreen = () => (
    <div className="App">
        <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
        </header>
        <NotFound />
    </div>
);

function App({ match }) {
    const [deliveryId, setDeliveryId] = useState(null);
    const [openCaseId, setOpenCaseId] = useState(null);
    const [openCase, setOpenCase] = useState(null);
    const [openCaseDetail, setOpenCaseDetail] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [deliveryNotFound, setDeliveryNotFound] = useState(true);
    const [delivery, setDelivery] = useState(null);
    const [needStatusUpdate, setStatusUpdateNeeded] = useState(false);
    const [status, setStatus] = useState(null);
    const [parcels, setParcels] = useState(null);
    const [traveller, setTraveller] = useState(null);
    // const [travellerRating, setTravellerRating] = useState(null);
    const [tokenSent, setTokenSent] = useState(false);
    const [currentToken, setToken] = useState(false);
    const [needChatUpdate, setChatUpdateNeeded] = useState(false);
    const [chatEvents, setChatEvents] = useState([]);
    const [chatOpen, setChatOpen] = useState(false);
    const [helpOpen, setHelpOpen] = useState(false);
    const [chatElement, setChatElement] = useState(null);
    const [deliverCode, setDeliverCode] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [oldDelivery, setOldDelivery] = useState(false);

    const [settings, setSettings] = useState({});

    const [routeInformation, setRouteInformation] = useState(undefined);

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.requestUserNotificationPermission) {
            requestNotificationPermission();
        }
        // eslint-disable-next-line
    }, [location.state?.requestUserNotificationPermission])


    const goToSettings = (settingsRecord = null) => {
        history.push({
            pathname: `settings/${deliveryId}`,
            state: {
                settings: settingsRecord ? settingsRecord : settings,
            },
        });
    };

    const getUserSettings = async (id) => {
        const settings = await getSettings(id);
        try {
            // navigate user to settings if submitted is not set
            if (!location?.state?.visitedSettings) {
                if (!settings.record.submitted) {
                    goToSettings(settings.record);
                    return false;
                } else if (settings.record.submitted && Number(settings.record.submitted) === 0) {
                    goToSettings(settings.record);
                    return false;
                } else if (Number(settings.record.submitted) === 1) {
                    setSettings(settings.record);
                    return true;
                }
            } else {
                if (settings.record) {
                    setSettings(settings.record);
                    return true;
                }

                if (location?.state?.visitedSettings) {
                    return true;
                }
                goToSettings();
                return false;
            }
        } catch (err) {
            console.log({ err });
            goToSettings();
            return false;
        }
    };

    useEffect(() => {
        if (deliveryId) {
            getPickupTime().then((routeData) => {
                setRouteInformation(routeData);
            });
            getDeliveryInfo(deliveryId);
            // get pickup hour
           /*  getUserSettings(deliveryId);
                getDeliveryInfo(deliveryId);
            */
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliveryId]);

    useEffect(() => {
        const localDeliveryId = match.params.deliveryId;

        const unregisterOnNotificationArrival = registerOnNotificationArrival();
        const unregisterChatHistoryUpdateKey = installChatHistoryUpdate(
            localDeliveryId,
        );

        setDeliveryId(localDeliveryId);

        loadLiveChat();

        return () => {
            if (typeof unregisterOnNotificationArrival === 'function') {
                unregisterOnNotificationArrival();
            }
            if (unregisterChatHistoryUpdateKey) {
                clearInterval(unregisterChatHistoryUpdateKey);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (delivery) {
            async function fetchAllData() {
                try {
                    // prevent settings screen redirect and request if delivery is delivered
                    if(['DELIVERED', 'REVIEWED'].indexOf(delivery.status) < 0) {
                        const response = await getUserSettings(deliveryId);
                        if (!response) {
                            return;
                        }
                    }
                } catch (err) {
                    setIsLoading(false)
                    console.log({err});
                }
                setIsLoading(false)
                getDeliveryStatus(deliveryId);
                getDeliveryParcelInfo(deliveryId);
                getDeliveryTravellerInfo(deliveryId);
                getOpenCaseReport(deliveryId);
                getDeliveryChatHistory(deliveryId);
                sendTokenToService();
                // getTravellerRatingInfo(deliveryId);
            }
            fetchAllData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delivery, currentToken]);

    useEffect(() => {
        if (chatElement) {
            if (chatOpen) {
                document.getElementsByTagName('body')[0].className =
                    'no-scroll';
            } else {
                document.getElementsByTagName('body')[0].className = '';
            }
        }
    }, [chatOpen, chatElement]);

    useEffect(() => {
        if (helpOpen) {
            scrollIntoView('Case-box-content-box');
        }
    }, [helpOpen]);

    useEffect(() => {
        if (openCase) {
            scrollIfIsCaseOpen();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openCase]);

    const scrollIfIsCaseOpen = () => {
        if (helpOpen) {
            scrollIntoView('Case-box-content-box');
        }
    };

    const scrollIntoView = (elementName) => {
        const el = document.getElementsByClassName(elementName)[0];
        if (el && el.lastElementChild) {
            el.lastElementChild.scrollIntoView();
        }
    };

    const sendTokenToService = () => {
        if (messaging && currentToken && !tokenSent) {
            registerNotificationToken(deliveryId, currentToken);
            setTokenSent(currentToken);
        }
    };

    const registerOnNotificationArrival = () => {
        if (messaging) {
            return messaging.onMessage(onNotification);
        }
    };

    const onNotification = (payload) => {
        if (payload.data.type === 'chat') {
            setChatUpdateNeeded(true);
        }

        if (payload.data.type === 'delivery.estimation_update') {
            setStatusUpdateNeeded(true);
        }
    };

    /**
     * Requests permission from the user to send browser notifications.
     */
    const requestNotificationPermission = () => {
        // only ask for permission if not selected by user before
        if (messaging && Notification.permission === 'default') {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                    messaging
                        .getToken()
                        .then((currentToken) => {
                            if (currentToken) {
                                setToken(currentToken);
                            } else {
                                // Show permission request.
                                console.log(
                                    'No Instance ID token available. Request permission to generate one.',
                                );
                            }
                        })
                        .catch((err) => {
                            console.log(
                                'An error occurred while retrieving token. ',
                                err,
                            );
                        });
                } else {
                    console.log('Unable to get permission to notify.');
                }
            });
        } else if (messaging && Notification.permission === 'granted') {
            // check if token has been refreshed and save it if that is the case
            messaging.getToken().then((retrievedToken) => {
                if (retrievedToken !== currentToken) {
                    setToken(retrievedToken);
                }
            }).catch((err) => {
                console.log(
                    'An error occurred while refreshing token. ',
                    err,
                );
            });
        }
    };

    /**
     * Function that handles permission request for notifications.
     * It first attempts to request notification permissions directly.
     * If the direct request fails or additional settings configuration is needed,
     * it navigates the user to the settings page.
     *
     * This function is designed to manage the permission flow, ensuring that
     * users are properly guided to adjust their notification settings if necessary.
     */
    const requestPermissionSettings = () => {
        requestNotificationPermission();
        goToSettings();
    };

    useEffect(() => {
        if (needChatUpdate) {
            getDeliveryChatHistory(deliveryId);
        }
    }, [needChatUpdate, deliveryId]);

    useEffect(() => {
        if (needStatusUpdate) {
            getDeliveryInfo(deliveryId);
            getDeliveryStatus(deliveryId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needStatusUpdate, deliveryId]);

    useEffect(() => {
        if (deliverCode) {
            acceptDelivery(deliverCode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliverCode]);

    const sendReview = (rating, review, callbackReset) => {
        if (rating && rating > 0) {
            sendTravellerReview(deliveryId, rating, review).then((result) => {
                getDeliveryInfo(deliveryId);
                getDeliveryStatus(deliveryId);
                getDeliveryTravellerInfo(deliveryId);
            });
        } else {
            callbackReset();
        }
    };

    const acceptDelivery = (code) => {
        acceptDeliveryWithCode(deliveryId, code).then((result) => {
            getDeliveryStatus(deliveryId);
            setDeliverCode(null);
        });
    };

    const sendChatMessage = (message) => {
        sendDeliverChatMessage(deliveryId, message).then((result) => {
            getDeliveryChatHistory(deliveryId);
        });
    };

    const getDeliveryInfo = async (id) => {
        try {
            const res = await getDeliveryInformation(id);
            const deliveryDate = new Date(res.deliver_date);
            const today = new Date();

            const diffTime = Math.abs(deliveryDate - today);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            // in case delivery was finished more than 7 days ago
            if (diffDays >= 5) {
                setOldDelivery(true);
            }
            setDeliveryNotFound(false);
            setDelivery(res);

            // setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log('getDeliveryInfo ERROR', error);
        }
    };

    const getDeliveryStatus = async (id) => {
        try {
            setStatus(await getDeliveryStatusHistory(id));
            setStatusUpdateNeeded(false);
        } catch (error) {
            console.log('getDeliveryStatus ERROR', error);
        }
    };

    const getDeliveryParcelInfo = async (id) => {
        try {
            setParcels(await getDeliveryParcels(id));
        } catch (error) {
            console.log('getDeliveryParcelInfo ERROR', error);
        }
    };

    const getDeliveryTravellerInfo = async (id) => {
        try {
            setTraveller(await getDeliveryTraveller(id));
        } catch (error) {
            console.log('getDeliveryTravellerInfo ERROR', error);
        }
    };

    /*
    const getTravellerRatingInfo = async (id) => {
        try {
            let response = await getTravellerRating(id);
            if( response) {
                setTravellerRating(response[0].rating);
            } else {
                setTravellerRating(0);
            }
        } catch (error) {
            console.log('getDeliveryTravellerInfo ERROR', error);
        }
    };
    */

    const installChatHistoryUpdate = (id) => {
        if (!messaging) {
            return setInterval(() => {
                if (id) {
                    getDeliveryChatHistory(id);
                }
            }, 30000);
        }
        return false;
    };

    const getDeliveryChatHistory = async (id) => {
        try {
            setChatEvents(await getDeliveryChat(id));
            setChatUpdateNeeded(false);
        } catch (error) {
            console.log('getDeliveryTravellerInfo ERROR', error);
        }
    };

    const getOpenCaseReport = async (id) => {
        try {
            const caseReport = await getCaseReport(id);
            if (caseReport !== null) {
                setOpenCase(caseReport);
                setOpenCaseId(caseReport.id);
                getOpenCaseReportDetails(id, caseReport.id);
            }
        } catch (error) {
            console.log('getOpenCaseReport ERROR', error);
        }
    };

    const getOpenCaseReportDetails = async (deliverId, reportId) => {
        try {
            const caseReportDetails = await getCaseReportDetails(
                deliverId,
                reportId,
            );
            setOpenCaseDetail(caseReportDetails);
        } catch (error) {
            console.log('getOpenCaseReportDetails ERROR', error);
        }
    };

    const caseSendAction = async (dataToSend) => {
        if (!openCaseId) {
            await openCaseReport(dataToSend);
        } else {
            await sendMessageToCaseReport(dataToSend);
        }
    };

    const openCaseReport = async (caseToSend) => {
        try {
            const caseId = await sendCaseReport(
                deliveryId,
                caseToSend.type,
                caseToSend.message,
            );
            if (caseId) {
                setOpenCaseId(caseId);
                getOpenCaseReport(deliveryId);
            }
        } catch (error) {
            console.log('openCaseReport ERROR', error);
        }
    };

    const sendMessageToCaseReport = async (dataToSend) => {
        try {
            await sendCaseReportMessage(
                deliveryId,
                openCase.number,
                dataToSend.message,
            );
            await getOpenCaseReport(deliveryId);
        } catch (error) {
            console.log('sendMessageToCaseReport ERROR', error);
        }
    };

    const checkDeliverInfo = () => {
        if (!deliveryNotFound) {
            return (
                <div className="App">
                    <header className="App-header">
                        <img src={logo} className="App-logo" alt="logo" />
                    </header>
                    <Main
                        oldDelivery={oldDelivery}
                        onSettingsPress={requestPermissionSettings}
                        onChatPress={() => {
                            requestNotificationPermission();
                            setChatOpen(true);
                        }}
                        onDeliveryAccept={(code) => setDeliverCode(code)}
                        onReview={sendReview}
                        openCaseId={openCaseId}
                        openCaseInfo={openCase}
                        onSeeCaseDetails={() => setHelpOpen(true)}
                        delivery={delivery}
                        status={status}
                        parcels={parcels}
                        traveller={traveller}
                        // travellerRating={travellerRating}
                        onHelpRequest={() => {
                            requestNotificationPermission();
                            setHelpOpen(true);
                        }}
                        routeInformation={routeInformation}
                    />
                    {chatOpen ? (
                        <Chat
                            chatRef={(el) => setChatElement(el)}
                            onBack={() => {
                                setChatOpen(!chatOpen);
                            }}
                            data={chatEvents}
                            myId={delivery.recipient.id}
                            onSend={sendChatMessage}
                        />
                    ) : null}
                    {helpOpen ? (
                        <Case
                            deliveryId={deliveryId}
                            delivery={delivery}
                            onChatPress={() => setChatOpen(true)}
                            openCaseId={openCaseId}
                            openCaseInfo={openCase}
                            openCaseDetail={openCaseDetail}
                            onClose={() => {
                                setHelpOpen(false);
                            }}
                            onOpenCase={caseSendAction}
                        />
                    ) : null}
                    <footer></footer>
                </div>
            );
        } else {
            return (<NotFoundScreen />);
        }
    };

    return isLoading ? (
        <div className="spinner-wrapper">
            <Spinner />
        </div>
    ) : (
        checkDeliverInfo()
    );
}

export default App;
