import React from 'react';
import { Screen } from '../components/Screen';

const NotFound = () => {
    return (
        <div>
            <Screen title={''}>
                <h2 className="text-title-1 text-title-2@medium-up spaced-24">
                    It seems that we dont have any delivery on the way to you.
                </h2>
                <div className="spacer-32"></div>
            </Screen>
        </div>
    );
};

export { NotFound };
