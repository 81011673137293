const deepCopy = (data) => JSON.parse(JSON.stringify(data));

class ApiResponseError extends Error {
    constructor(error) {
        super(error.message); // (1)
        this.name = 'ApiResponseError'; // (2)

        let bodyMessage = error?.response?.data
            ? deepCopy(error.response.data)
            : {};
        if (bodyMessage?.error && bodyMessage?.error?.message) {
            bodyMessage = bodyMessage.error.message;
        }

        this.data = bodyMessage; // 3
        this.data.httpStatusCode = error?.response?.status;
        this.data.httpStatusMEssage = error?.response?.statusText;
    }

    getData = () => {
        return this.data;
    };
}

export { ApiResponseError };
