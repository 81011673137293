import apiEngine from './commHelper';
import { ENDPOINTS } from './endpoints';

const getDeliveryInformation = (deliveryId) => {
    return apiEngine({
        endpoint: ENDPOINTS.DELIVERY.GET(deliveryId),
    }).then((apiReply) => apiReply.record);
};

const getPickupTime = () => {
    return apiEngine({
        endpoint: ENDPOINTS.DELIVERY.GET_PICKUP_TIME(),
        params: { moment: new Date().getTimezoneOffset() }
    }).then((apiReply) => apiReply.record);
};

const getDeliveryStatusHistory = (deliveryId) => {
    return apiEngine({
        endpoint: ENDPOINTS.DELIVERY.GET_STATUS_HISTORY(deliveryId),
    }).then((apiReply) => apiReply.records);
};

const getDeliveryParcels = (deliveryId) => {
    return apiEngine({
        endpoint: ENDPOINTS.DELIVERY.GET_PARCELS(deliveryId),
    }).then((apiReply) => apiReply.records);
};

const getDeliveryTraveller = (deliveryId) => {
    return apiEngine({
        endpoint: ENDPOINTS.DELIVERY.GET_TRAVELLER(deliveryId),
    }).then((apiReply) => apiReply.record);
};

const getTravellerRating = (deliveryId) => {
    return apiEngine({
        endpoint: ENDPOINTS.DELIVERY.GET_TRAVELLER_RATING(deliveryId),
    }).then((apiReply) => apiReply.records);
};

const registerNotificationToken = (deliveryId, token) => {
    return apiEngine({
        endpoint: ENDPOINTS.DELIVERY.SEND_NOTIFICATION_TOKEN(deliveryId),
        data: { topic: token },
    }).then((apiReply) => apiReply.record);
};

const getDeliveryChat = (deliveryId) => {
    return apiEngine({
        endpoint: ENDPOINTS.DELIVERY.GET_CHAT(deliveryId),
    }).then((apiReply) => apiReply.records);
};

const sendDeliverChatMessage = (deliveryId, message) => {
    return apiEngine({
        endpoint: ENDPOINTS.DELIVERY.SEND_CHAT_MESSAGE(deliveryId),
        data: { message },
    }).then((apiReply) => apiReply.records);
};

const acceptDeliveryWithCode = (deliveryId, code) => {
    return apiEngine({
        endpoint: ENDPOINTS.DELIVERY.ACCEPT_DELIVERY(deliveryId, code),
    }).then((apiReply) => apiReply);
};

const sendTravellerReview = (deliveryId, rating, review) => {
    return apiEngine({
        endpoint: ENDPOINTS.DELIVERY.SEND_REVIEW(deliveryId),
        data: { rating, comment: review },
    }).then((apiReply) => apiReply);
};

const sendCaseReport = (deliveryId, type, message, files = undefined) => {
    const form = new FormData();
    form.append('type', type);
    form.append('message', message);
    // form.append('my_file', fs.createReadStream('/foo/bar.jpg'));

    return apiEngine({
        endpoint: ENDPOINTS.DELIVERY.REPORT(deliveryId),
        data: form,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
        },
    }).then((apiReply) => apiReply);
};

const sendCaseReportMessage = (
    deliveryId,
    caseNumber,
    message,
    files = undefined,
) => {
    const form = new FormData();
    form.append('message', message);
    // form.append('my_file', fs.createReadStream('/foo/bar.jpg'));

    return apiEngine({
        endpoint: ENDPOINTS.DELIVERY.REPORT_MESSAGE(deliveryId, caseNumber),
        data: form,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
        },
    }).then((apiReply) => apiReply);
};

const getCaseReport = (deliveryId) => {
    return apiEngine({
        endpoint: ENDPOINTS.DELIVERY.GET_REPORT(deliveryId),
    }).then((apiReply) => {
        if (!apiReply.records) {
            return null;
        }
        return apiReply.records[0];
    });
};

const getCaseReportDetails = (deliveryId, reportId) => {
    return apiEngine({
        endpoint: ENDPOINTS.DELIVERY.GET_REPORT_DETAILS(deliveryId, reportId),
    }).then((apiReply) => apiReply.records);
};

const sendSettings = (deliveryId, settings) => {
    return apiEngine({
        endpoint: ENDPOINTS.DELIVERY.SEND_SETTINGS(deliveryId),
        data: settings,
    }).then((apiReply) => apiReply);
};

const getSettings = (deliveryId) => {
    return apiEngine({
        endpoint: ENDPOINTS.DELIVERY.GET_SETTINGS(deliveryId),
    }).then((apiReply) => apiReply);
};

export {
    getDeliveryInformation,
    getDeliveryStatusHistory,
    getDeliveryParcels,
    getDeliveryTraveller,
    getCaseReport,
    getCaseReportDetails,
    registerNotificationToken,
    getDeliveryChat,
    sendDeliverChatMessage,
    acceptDeliveryWithCode,
    sendTravellerReview,
    sendCaseReport,
    sendCaseReportMessage,
    sendSettings,
    getSettings,
    getTravellerRating,
    getPickupTime,
};
