const FLYPE_API_URL = process.env.REACT_APP_FLYPE_API_URL;
const TRACKING_FUNCTIONS = `${FLYPE_API_URL}${process.env.REACT_APP_TRACKING_FUNCTIONS}`;

const API_ACTION = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
};

const ENDPOINTS = {
    DELIVERY: {
        GET: (deliveryId) => ({
            URL: `${TRACKING_FUNCTIONS}/${deliveryId}`,
            ACTION: API_ACTION.GET,
        }),
        GET_PICKUP_TIME: () => ({
            URL: `${TRACKING_FUNCTIONS}/route/time`,
            ACTION: API_ACTION.GET,
        }),
        GET_STATUS_HISTORY: (deliveryId) => ({
            URL: `${TRACKING_FUNCTIONS}/${deliveryId}/status_history`,
            ACTION: API_ACTION.GET,
        }),
        GET_PARCELS: (deliveryId) => ({
            URL: `${TRACKING_FUNCTIONS}/${deliveryId}/parcels`,
            ACTION: API_ACTION.GET,
        }),
        GET_TRAVELLER: (deliveryId) => ({
            URL: `${TRACKING_FUNCTIONS}/${deliveryId}/traveller`,
            ACTION: API_ACTION.GET,
        }),
        GET_TRAVELLER_RATING: (deliveryId) => ({
            URL: `${TRACKING_FUNCTIONS}/${deliveryId}/traveller/rating`,
            ACTION: API_ACTION.GET,
        }),
        SEND_NOTIFICATION_TOKEN: (deliveryId) => ({
            URL: `${TRACKING_FUNCTIONS}/${deliveryId}/update_token`,
            ACTION: API_ACTION.PUT,
        }),
        GET_CHAT: (deliveryId) => ({
            URL: `${TRACKING_FUNCTIONS}/${deliveryId}/chat`,
            ACTION: API_ACTION.GET,
        }),
        SEND_CHAT_MESSAGE: (deliveryId) => ({
            URL: `${TRACKING_FUNCTIONS}/${deliveryId}/message`,
            ACTION: API_ACTION.POST,
        }),
        ACCEPT_DELIVERY: (deliveryId, code) => ({
            URL: `${TRACKING_FUNCTIONS}/${deliveryId}/delivered/${code}`,
            ACTION: API_ACTION.PUT,
        }),
        SEND_REVIEW: (deliveryId) => ({
            URL: `${TRACKING_FUNCTIONS}/${deliveryId}/traveller/review`,
            ACTION: API_ACTION.POST,
        }),
        GET_REPORT: (deliveryId) => ({
            URL: `${TRACKING_FUNCTIONS}/${deliveryId}/report`,
            ACTION: API_ACTION.GET,
        }),
        GET_REPORT_DETAILS: (deliveryId, reportId) => ({
            URL: `${TRACKING_FUNCTIONS}/${deliveryId}/report/${reportId}`,
            ACTION: API_ACTION.GET,
        }),
        REPORT: (deliveryId) => ({
            URL: `${TRACKING_FUNCTIONS}/${deliveryId}/report`,
            ACTION: API_ACTION.POST,
        }),
        REPORT_MESSAGE: (deliveryId, reportNumber) => ({
            URL: `${TRACKING_FUNCTIONS}/${deliveryId}/report/${reportNumber}/message`,
            ACTION: API_ACTION.POST,
        }),
        SEND_SETTINGS: (deliveryId) => ({
            URL: `${TRACKING_FUNCTIONS}/${deliveryId}/settings`,
            ACTION: API_ACTION.PUT,
        }),
        GET_SETTINGS: (deliveryId) => ({
            URL: `${TRACKING_FUNCTIONS}/${deliveryId}/settings`,
            ACTION: API_ACTION.GET,
        }),
    },
};

export { API_ACTION, ENDPOINTS };
