import React from 'react';

import './style.scss';

/* const FLYPER_EXPERIENCE = {
    1: 'Flyper',
    2: 'High Flyper',
    3: 'Super Flyper',
};*/

const MONTH = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

/*
const getStarRating = (rating) => {
    let starsOn;
    // show 5 stars if Flyper is new
    if (rating === 0 || rating === null){
        starsOn = 5
    } else {
        starsOn = Math.round(5 * rating);
    }
    return Array(5)
        .fill(1)
        .map((item, idx) => {
            if (idx < starsOn) {
                return (
                    <span
                        key={`star${idx}`}
                        className="Icon Icon-star-filled"></span>
                );
            }
            return <span key={`star${idx}`} className="Icon Icon-star"></span>;
        });
};
*/

const TravellerInfo = ({ data/*,travellerRating*/ }) => {
    if (!data) {
        return null;
    }
    return (
        <div>
            <h2 className="text-title-2 spaced-24">Delivered by</h2>
            <div className="Traveler spaced-16">
                <div className="userPicture">
                    {/* eslint-disable-next-line jsx-a11y/alt-text */}
                    <img src={data.picture}></img>
                </div>

                <div className="Traveler__info spaced-left-16">
                    <div className="Traveler__headline text-title-2">
                        {data.firstName} {/*data.lastName*/}
                    </div>
                    <div className="Traveler__subheadline text-footnote">
                        {/*FLYPER_EXPERIENCE[data.experience]*/} Flyper since{' '}
                        {MONTH[new Date(data.registrationDate).getMonth()]}{' '}
                        {new Date(data.registrationDate).getFullYear()}
                    </div>
                    {/*
                        <div className="Traveler__rating">
                            {getStarRating(travellerRating)}
                        </div>
                    */}
                </div>
            </div>
        </div>
    );
};

export { TravellerInfo };
