import React from 'react';
import {
    prepareMinimumTimeOfDeparture,
    minimumHourMinutesFromDateString,
    dateFromDateString,
    getEstimation,
} from '../../libs/utils';
import { Screen } from '../Screen';
import { FabButton } from '../Button';
import { Rating } from '../Rating';
import './style.scss';

const DeliveryArea = ({
    data,
    onSettingsPress,
    onChatPress,
    onReview,
    onHelpRequest,
    openCaseId,
    onSeeCaseDetails,
    openCaseInfo,
    oldDelivery,
    routeInformation,
    deliveryStatusDetail,
}) => {

    /**
     * returns delivery date based on the status list detail.
     *
     * @param {Array<{public_id: string, moment: string, status: string}>} statusListDetail - The list of status details.
     * @returns {String|null} - The delivery date or null if not found.
     */
    const getDeliveryDate = (statusListDetail) => {
        if (!statusListDetail || statusListDetail?.length <= 0) {
            return null;
        }
        for (const statusDetail of statusListDetail) {
            if (statusDetail.status === 'DELIVERED') {
                return dateFromDateString(statusDetail.moment);
            }
        }
        return null;
    };

    const getTitle = (status) => {
        switch (status) {
            case 'IN_STORE':
                return 'Planned delivery'
            case 'PICKED_UP':
                return 'In transit';

            case 'DELIVERED':
            case 'REVIEWED':
                return 'Delivered';
            default:
                return 'Planned delivery';
        }
    };
    const estimatedEndTime = (deliveryStart) => {
        const deliveryEnd = new Date(deliveryStart);
        const weekDay = deliveryEnd.getDay();
        if (data.start_moment) {
            deliveryEnd.setHours(
                deliveryEnd.getHours(),
                deliveryEnd.getMinutes() + 20,
            );
        } else {
            const endHour = weekDay === 0 || weekDay === 6 ? routeInformation ? (routeInformation.pickupHour + routeInformation.shiftLength): 22 : routeInformation ? (routeInformation.pickupHour + routeInformation.shiftLength): 22;
            deliveryEnd.setHours(endHour, 0);
        }
        return deliveryEnd;
    };

    const deliveryBegin = prepareMinimumTimeOfDeparture(
        new Date(data.expected),
        data.start_moment,
        routeInformation ? routeInformation.pickupHour : 15,
    );
    const deliveryEnd = estimatedEndTime(deliveryBegin);
    return (
        <Screen
            header={
                ['DELIVERED', 'REVIEWED'].indexOf(data.status) < 0 ? (
                    <>
                        <FabButton
                            onClick={onSettingsPress}
                            title="Settings"
                            icon="settings"></FabButton>

                            <FabButton
                                onClick={onChatPress}
                                title="Message"
                                icon="chat"></FabButton>

                    </>
                ) : null
            }
            openCaseId={openCaseId}
            openCaseInfo={openCaseInfo}
            onSeeCaseDetails={onSeeCaseDetails}
            title={getTitle(data.status)}
            footer={
                ['DELIVERED'].indexOf(data.status) >= 0 ? (
                <Rating
                active={data.status === 'DELIVERED'}
                onReview={onReview}
                /> ) : null
            }>
            {['DELIVERED'].indexOf(data.status) < 0 || !data ? (
            <h2 className="text-title-1 text-title-2@medium-up spaced-24">
                {dateFromDateString(getEstimation(data.deliver_date))}
            </h2> ) :
                <h2 className="text-title-1 text-title-2@medium-up spaced-24">
                    {getDeliveryDate(deliveryStatusDetail)}
                </h2>
            }
            {['DELIVERED', 'REVIEWED'].indexOf(data.status) < 0 ? (
                <h2 className="text-title-1 text-title-2@medium-up">
                    {minimumHourMinutesFromDateString(deliveryBegin, data.start_moment, routeInformation ? routeInformation.pickupHour : 15)} -{' '}
                    {minimumHourMinutesFromDateString(deliveryEnd, data.start_moment, routeInformation? routeInformation.pickupHour : 15, true)}
                </h2>
            ) : null}
            <h3 className="text-title-2 spaced-16 text-headline@medium-up">
                {data.recipient.address.streetNumber ? data.recipient.address.streetNumber + ' ': ''}
                {data.recipient.address.street1}{data.recipient.address.location ? ', ' : ''}
                {data.recipient.address.location}
            </h3>
            {!openCaseId && !oldDelivery ? (
                <p
                    onClick={onHelpRequest}
                    style={{
                        paddingTop: 30,
                        color: '#2EB4FF',
                        fontSize: 20,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                    }}>
                    I need help with my delivery
                </p>
            ) : null}

            <div className="spaced-16"></div>

            {/* {data.status === 'PICKED_UP' ? null : data.status === // <Button title="Track delivery [cta]"></Button>
              'DELIVERING' ? (
                <DeliverForm onDeliveryAccept={onDeliveryAccept} />
            ) : null} */}
        </Screen>
    );
};

export default DeliveryArea;
