import { API_ACTION } from './endpoints';
import flypeApi from './communication';

export default function api({
    endpoint,
    params = {},
    data = {},
    headers = {},
    extra = {},
}) {
    const { URL, ACTION } = endpoint;
    switch (ACTION) {
        case API_ACTION.POST:
            return flypeApi.post(URL, params, data, headers, extra);

        case API_ACTION.PUT:
            return flypeApi.put(URL, params, data, headers, extra);

        case API_ACTION.DELETE:
            return flypeApi.delete(URL, params, data, headers, extra);

        case API_ACTION.GET:
        default:
            return flypeApi.get(URL, params, headers, extra);
    }
}
