import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import firebaseConfig from './config';

let messaging = null;
if (firebase.messaging.isSupported()) {
    const initializedFirebaseApp = firebase.initializeApp(firebaseConfig);
    messaging = initializedFirebaseApp.messaging();
} else {
    console.warn('Browser does not support Push Notification');
}

export { messaging };
