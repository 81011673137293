import React from 'react';

import DeliveryArea from '../components/Delivery';
import { StatusCards } from '../components/StatusCard';
import { ParcelInfo } from '../components/ParcelInfo';
import { TravellerInfo } from '../components/TravellerInfo';

const Main = ({
    onSettingsPress,
    onChatPress,
    onDeliveryAccept,
    onReview,
    delivery,
    status,
    parcels,
    traveller,
    onHelpRequest,
    openCaseId,
    onSeeCaseDetails,
    openCaseInfo,
    oldDelivery,
    routeInformation,
    // travellerRating,
}) => {
    return (
        <div>
            <DeliveryArea
                oldDelivery={oldDelivery}
                data={delivery}
                onSettingsPress={onSettingsPress}
                onChatPress={onChatPress}
                onDeliveryAccept={onDeliveryAccept}
                onReview={onReview}
                onHelpRequest={onHelpRequest}
                openCaseId={openCaseId}
                onSeeCaseDetails={onSeeCaseDetails}
                openCaseInfo={openCaseInfo}
                routeInformation={routeInformation}
                deliveryStatusDetail={status}
            />
            <StatusCards
                data={status}
                delivery={delivery}
                traveller={traveller}
            />

            <section className="Section bg-white">
                <div className="Grid Grid--small Grid--centered">
                    <div className="Grid__column">
                        <div className="spacer-16 spacer-48@medium-up"></div>

                        <ParcelInfo data={parcels} />

                        <TravellerInfo data={traveller} /*travellerRating={travellerRating}*/ />

                        <div className="spacer-32"></div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export { Main };
