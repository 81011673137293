import { getUserLocale } from 'get-user-locale';

/**
 * Retrieves and enforces the user locale.
 *
 * This function first attempts to fetch the user's locale using the `getUserLocale` function.
 * If the fetched locale is not suitable, it defaults to "en-US". The function then applies
 * the `forceEnglishAsLocale` function to potentially modify the locale as needed.
 *
 * @returns {string} The resulting locale after applying necessary adjustments.
 */
const getLocale = () => {
    // default locale is en-US if not fetched
    const locale = getUserLocale();
    return forceEnglishAsLocale(locale);
};

/**
 * Adjusts the given user locale to its English equivalent if it contains a hyphen.
 *
 * @param {string} userLocale - The locale string provided by the user.
 * @return {string|undefined} Returns the modified locale in English, or undefined if input is invalid or no hyphen found.
 */
const forceEnglishAsLocale = userLocale => {
    if (!userLocale) {
        return undefined;
    }
    const parts = userLocale.split("-");
    if (parts.length > 1) {
        return `en-${parts[1]}`;
    } else {
        return 'en-US';
    }
};

export {getLocale}
