import React, { useState } from 'react';

import { Button } from '../Button';

import './style.scss';
import '../../foundation/Icon.scss';

const Rating = ({ onReview, starNumber = 5, active = false }) => {
    const [rating, setRating] = useState(0);
    const [review, setReview] = useState(null);
    const [buttonState, setButtonState] = useState(false);

    const prepareReview = () => {
        if (typeof onReview === 'function') {
            setButtonState(true);
            onReview((rating / starNumber) * 100, review, resetButtonState);
        }
    };

    // only called in case user tries to submit a review without stars rating
    const resetButtonState = () => {
        setButtonState(false);
    }

    const starHeight = 20;
    const stars = Array(starNumber)
        .fill(1)
        .map((item, idx) => {
            if (!active) {
                return (
                    <span
                        key={idx}
                        className="Icon Icon-star-inactive"
                        style={{ height: starHeight }}></span>
                );
            } else {
                const classes =
                    rating > idx ? 'Icon Icon-star-filled' : 'Icon Icon-star';

                return (
                    <span
                        onClick={() => setRating(idx + 1)}
                        key={idx}
                        className={classes}
                        style={{ height: starHeight }}></span>
                );
            }
        });

    if (!active) {
        return (
            <div
                style={{
                    backgroundColor: '#F0F0F0',
                    borderStyle: 'solid',
                    borderColor: '#493AEB',
                    borderWidth: 1,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingRight: 0,
                    paddingLeft: 0,
                    borderRadius: 22,
                    height: '100%',
                    marginBottom: 10,
                }}>
                <div>{stars}</div>
            </div>
        );
    }
    return (
        <div>
            <div
                style={{
                    backgroundColor: '#F0F0F0',
                    borderStyle: 'solid',
                    borderColor: '#493AEB',
                    borderWidth: 1,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingRight: 0,
                    paddingLeft: 0,
                    borderRadius: 22,
                    height: '100%',
                    marginBottom: 10,
                }}>
                <div>{stars}</div>
                <textarea
                    onChange={(event) => setReview(event.target.value)}
                    placeholder={'Tell us more'}
                    style={{
                        marginTop: 10,
                        // height: 200,
                        width: '85%',
                        resize: 'none',
                        fontFamily: 'inherit',
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: '#c7c7c7',
                        fontSize: 16,
                        lineHeight: 1.5,
                        color: '#707070',
                        padding: 10,
                        borderRadius: 22,
                    }}
                />
            </div>
            {buttonState ? null : (
                <Button
                    title={'Submit'}
                    type={'secondary'}
                    onClick={prepareReview}
                />
            )}
        </div>
    );
};

export { Rating };
