import React, { useState, useEffect } from 'react';

import logo from '../../assets/logo.svg';
import '../../App.scss';
import './style.scss';

import { FabButton, Button } from '../Button';
import { useHistory, useLocation } from 'react-router-dom';

import { sendSettings } from '../../libs/api';

const SettingsScreen = ({ match }) => {
    const history = useHistory();
    const location = useLocation();
    const [buildingType, setBuildingType] = useState('');
    const [parcelOutside, setParcelOutside] = useState(true);
    const [floorNumber, setFloorNumber] = useState(null);
    const [notificationType, setNotificationType] = useState('doorbell');
    const [otherInfo, setOtherInfo] = useState('');

    const [confirmation, setConfirmation] = useState(false);

    const { deliveryId } = match.params;
    // const { settings } = location.state;

    const goToHomePage = (params = null) => {
        history.push({
            pathname: `/${deliveryId}`,
            state: {
                normalRoute: true,
                visitedSettings: true,
                requestUserNotificationPermission: !!(params && (params?.buttonClick || params?.willDoItLater)),
                ...params,
            },
        });
    };

    const sendData = () => {
        setConfirmation(true);
        sendSettings(deliveryId, {
            attributes: {
                leave_outside: parcelOutside ? '1' : '0',
                place_type: buildingType,
                floor: Number(floorNumber || 0),
                notify_by: notificationType,
                obs: otherInfo,
                submitted: '1',
            },
        })
            .then((res) => {
                setTimeout(() => {
                    setConfirmation(false);
                    setBuildingType('');
                    goToHomePage();
                }, 100);
            })
            .catch((err) => console.log({ err }));
    };

    useEffect(() => {
        if (location.state && location.state.settings) {
            const {
                floor,
                leave_outside,
                notify_by,
                obs,
                place_type,
            } = location.state.settings;
            setFloorNumber(Number(floor));
            setParcelOutside(Number(leave_outside) === 1);
            setNotificationType(notify_by);
            setOtherInfo(obs);
            setBuildingType(place_type);
        } else {
            goToHomePage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.state]);

    const parcelOutsideDoor = () => {
        return (
            <div className="parcel_outside__wrapper">
                <h3> Leave package outside your door?</h3>
                <div
                    className="parcel_outside__options"
                    style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="switch_wrapper">
                        <div className="switch">
                            <input
                                name="switch"
                                type="checkbox"
                                checked={parcelOutside}
                                onChange={() =>
                                    setParcelOutside(!parcelOutside)
                                }
                            />
                            <span className="slider round"></span>
                        </div>
                        <p className="switch_status">
                            {parcelOutside ? 'Yes' : 'No'}
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    const getNotifiedBy = () => {
        return (
            <div className="notification__wrapper">
                <h3> Notify me on arrival?</h3>
                <div className="notification__options">
                    <div className="radio">
                        <label>
                            <input
                                type="radio"
                                value="doorbell"
                                onChange={(e) =>
                                    setNotificationType(e.target.value)
                                }
                                checked={notificationType === 'doorbell'}
                            />
                            <span className="checkmark fill"></span>
                            Ring the doorbell
                        </label>
                    </div>
                    <div className="radio">
                        <label>
                            <input
                                type="radio"
                                value="knock"
                                onChange={(e) =>
                                    setNotificationType(e.target.value)
                                }
                                checked={notificationType === 'knock'}
                            />
                            <span className="checkmark fill"></span>
                            Knock on my door
                        </label>
                    </div>
                </div>
            </div>
        );
    };

    const changeFloor = (direction) => {
        let floor = floorNumber || 0;
        if (direction === 'down') {
            if (floorNumber > 0) {
                setFloorNumber(floor - 1);
            }
            return;
        }
        setFloorNumber(floor + 1);
    };

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
            </header>
            <div className="card_component">
                <div className="card_component__back-button">
                    <FabButton
                        icon="back-arrow"
                        onClick={() => goToHomePage({buttonClick: true})}
                    />
                </div>
                <div className="content_width">
                    <h2>Help us get your package delivered to you</h2>
                    <div className="Icon Icon-flype Icon-flype--large spaced-32"></div>
                    <div className="building_type">
                        <h3> You live in a </h3>
                        <div className="building_type__choices">
                            <button
                                className={`choices_button ${
                                    buildingType === 'villa' ? 'is-active' : ''
                                }`}
                                onClick={() => setBuildingType('villa')}>
                                House
                            </button>
                            <button
                                className={`choices_button ${
                                    buildingType === 'apartment'
                                        ? 'is-active'
                                        : ''
                                }`}
                                onClick={() => setBuildingType('apartment')}>
                                Apartment
                            </button>
                        </div>
                    </div>
                    {buildingType && (
                        <>
                            {parcelOutsideDoor()}
                            {buildingType === 'apartment' && (
                                <div className="floor_number__wrapper">
                                    <h3> Which floor?</h3>
                                    <div className="input_floor_container">
                                        <button
                                            className="floor_btn"
                                            onClick={() => changeFloor('down')}>
                                            <p>-</p>
                                        </button>
                                        <input
                                            className="floor_number__input"
                                            type="number"
                                            value={'' + floorNumber}
                                            onChange={(e) =>
                                                setFloorNumber(
                                                    Number(e.target.value),
                                                )
                                            }
                                        />
                                        <button
                                            className="floor_btn"
                                            onClick={() => changeFloor('up')}>
                                            <p>+</p>
                                        </button>
                                    </div>
                                </div>
                            )}

                            {!parcelOutside && getNotifiedBy()}
                            <div className="other_info__wrapper">
                                <h3> Other information (e.g port code)</h3>
                                <textarea
                                    placeholder="e.g. port code, access through courtyard…"
                                    value={otherInfo}
                                    onChange={(e) =>
                                        setOtherInfo(e.target.value)
                                    }
                                />
                            </div>
                            <Button
                                title="Save settings"
                                onClick={() => sendData()}
                            />
                            {confirmation && (
                                <p style={{ color: '#007965' }}>
                                    Thank you for setting delivery settings
                                </p>
                            )}
                            <button
                                className="skip_button"
                                onClick={() =>
                                    goToHomePage({ willDoItLater: true })
                                }>
                                I will do this later
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

// /C-EAC17F3E

export default SettingsScreen;
